"use client";

import React, { useState } from "react";
import { Input, AutoComplete } from "antd";
import Button from "@/common/components/button/Button";
import { BUTTON_LABEL, PLACEHOLDERS } from "@/common/constants/constants";
import filterKeywordsData from "@/data/domain/filterKeywords.json";

import { useRouter } from "next/router";

import styles from "./InputsSection.module.scss";

const InputsSection = () => {
  const router = useRouter();
  const [options, setOptions] =
    useState<{ value: string }[]>(filterKeywordsData);
  const [keyword, setKeyword] = useState<string>("");
  const [xlocation, setXlocation] = useState<string>("");

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    router.push({
      pathname: "/jobs",
      query: {
        ...(keyword ? { keyword } : {}),
        ...(xlocation ? { xlocation } : {}),
      },
    });
    setKeyword("");
    setXlocation("");
  };

  function onSelect(value: string) {
    setKeyword(value);
  }

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit}>
      <AutoComplete
        suffixIcon={
          <img src={"/icons/briefcase-icon.svg"} alt="briefcase Icon" />
        }
        options={options}
        value={keyword}
        allowClear={true}
        filterOption={(inputValue, option) =>
          option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        onChange={(value: string) => setKeyword(value)}
        onSelect={onSelect}
        placeholder={PLACEHOLDERS.ENTER_JOB_OR_KEYWORD}
        className={styles.inputLocation}
      />

      <Input
        prefix={<img src={"/icons/location-icon.svg"} alt="location Icon" />}
        placeholder={PLACEHOLDERS.LOCATION}
        className={styles.inputLocation}
        value={xlocation}
        onChange={(event) => setXlocation(event.target.value)}
      />
      <Button text={BUTTON_LABEL.FIND_JOB} icon={"/icons/search-icon.svg"} />
    </form>
  );
};

export default InputsSection;
