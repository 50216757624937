import "@fontsource/space-grotesk/700.css";
import "@fontsource/inter";
import "../styles/globals.scss";
import "../styles/variables.css";
import "../common/theme/theme.scss";
import MainLayout from "@/common/components/mainLayout/MainLayout";
import { AppProps } from "next/app";

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <div className="bodyWrapper">
      <MainLayout>
        <Component {...pageProps} />
      </MainLayout>
    </div>
  );
}
