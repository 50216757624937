import React from 'react'
import footerData from '@/data/domain/footer.json'
import Link from 'next/link'
import { v4 as uuidv4 } from 'uuid'

import styles from './Footer.module.scss'

const Footer = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.footerNavigationWrapper}>
        {footerData.map((item) => {
          return (
            <Link
              key={uuidv4()}
              href={item.path}
              className={styles.footerNavigationItem}
            >
              {item.name}
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default Footer
