import Head from "next/head";
import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";

const MainLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Head>
        <link rel="icon" href={`/icons/favicon.svg`} />
        <link rel="shortcut icon" href={`/icons/favicon.svg`} />
      </Head>
      <Header />
      <div className={"children"}>{children}</div>
      <Footer />
    </>
  );
};

export default MainLayout;
