"use client";
import React, { useState } from "react";
import { Input } from "antd";
import { IMAGE_ALT, PLACEHOLDERS } from "@/common/constants/constants";

import styles from "./InputsMobileSection.module.scss";
import Link from "next/link";

const InputsMobileSection = () => {
  const [keyword, setKeyword] = useState<string>("");
  return (
    <div className={styles.wrapper}>
      <Input
        placeholder={PLACEHOLDERS.ENTER_JOB}
        className={styles.inputEnterJob}
        value={keyword}
        onChange={(event) => setKeyword(event.target.value)}
      />
      <Link
        href={{
          pathname: "/jobs",
          query: {
            ...(keyword ? { keyword } : {}),
          },
        }}
      >
        <button className={styles.searchButton}>
          <img src="/icons/search-icon.svg" alt={IMAGE_ALT.SEARCH_ICON} />
        </button>
      </Link>
    </div>
  );
};

export default InputsMobileSection;
