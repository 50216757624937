"use client";

import React from "react";
import Link from "next/link";
import InputsSection from "../inputs-section/InputsSection";
import { PAGE_TITLE, PATHS } from "@/common/constants/constants";
import { usePathname } from "next/navigation";
import InputsMobileSection from "../inputs-mobile-sectiion/InputsMobileSection";

import styles from "./Header.module.scss";

export const Header = () => {
  const pathname = usePathname();

  return (
    <div className={styles.wrapper}>
      <div className={styles.webHeader}>
        <Link href={PATHS.HOME_PAGE} className={styles.homeTitle}>
          {PAGE_TITLE.JOZYJOBS}
        </Link>
        {pathname !== PATHS.HOME_PAGE && (
          <div>
            <InputsSection />
          </div>
        )}
        <Link href={PATHS.ABOUT_US}> {PAGE_TITLE.ABOUT_US}</Link>
      </div>

      <div className={styles.mobileHeader}>
        <Link href={PATHS.HOME_PAGE} className={styles.homeTitle}>
          {PAGE_TITLE.JOZYJOBS}
        </Link>
        <InputsMobileSection />
      </div>
    </div>
  );
};
export default Header;
