import React from "react";
import { IButton } from "@/common/types/Types";

import styles from "./Button.module.scss";

const Button = (props: IButton) => {
  const { text, icon } = props;

  return (
    <button className={styles.wrapper} type="submit">
      {icon && <img src={icon} alt={icon} className={styles.icon} />}
      <div>{text}</div>
    </button>
  );
};

export default Button;
