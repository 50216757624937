export enum PAGE_TITLE {
  PRIVACY = "Privacy",
  TERMS_AND_CONDITIONS = "Terms and Conditions",
  BROWSE_JOBS = "Browse jobs",
  ABOUT_US = "About us",
  ABOUT_US_TITLE = "Jozy Jobs: Bridging Worlds, Building Futures",
  BROWSE_BY_TITLE = "Browse by title",
  BROWSE_BY_LOCATION = "Browse by location",
  JOZYJOBS = "JozyJobs",
  CONTACT_US = "Contact us",
  JOBS_LIST = "Jobs List",
  JOZY_JOBS = "Jozy Jobs: Your Premier Destination for Job Listings & Opportunities",
}

export enum LABELS {
  WHAT_PEOPLE_SAY = "What People Say",
  ABOUT_COMPANY = "About",
  PERSONALIZING_EACH = "Personalizing each and every search for millions of job seekers.",
  JOZYJOB_IN_NUMBERS = "JozyJobs in numbers",
  WHAT_MAKES_US_SPECIAL = "What makes us special?",
  JOBS_TITLE_PART_1 = "Best",
  JOBS_TITLE_PART_2 = "Job Offers - in",
  JOBS_TITLE_PART_2_1 = "Offers - in",
  JOBS_TITLE_PART_3 = "UK",
  JOBS_TITLE_PART_4 = "- Updated",
  RELATED_JOBS = "Related Jobs",
  NAME = "Name",
  EMAIL = "Email",
  MESSAGE = "Message",
}

export enum BUTTON_LABEL {
  FIND_JOB = "Find job",
  VIEW_SALARY = "View salary",
  SHOW_MORE = "Show more",
  SUMBIT = "Submit",
}

export enum PLACEHOLDERS {
  ENTER_JOB_OR_KEYWORD = "Enter job or keyword...",
  LOCATION = "Location...",
  ENTER_JOB = "Enter job..",
}

export enum PATHS {
  HOME_PAGE = "/",
  ABOUT_US = "/about",
}

export enum IMAGE_ALT {
  SEARCH_ICON = "search-icon",
}

export const VALID_QUERY_PARAMS = ["keyword", "location"];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
